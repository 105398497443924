import React from 'react'

import { Hero, PrivacyPolicies, Social } from './components'

export default () => (
  <>
    <Hero />
    <PrivacyPolicies />
    <Social />
  </>
)
