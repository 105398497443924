import React from 'react'

import { Container } from '../../../components'

export default () => (
  <Container id="hero">
    <div className="pt-5 my-2 text-center">
      <img
        className="d-block mx-auto mb-5 mt-5"
        src="/appolus-logo.png"
        alt="Logo Appolus Tecnologia"
        height="100"
      />
      <div className="col-lg-6 mx-auto my-5 pt-5">
        <p className="lead">
          Nosso site estará disponível em breve...
        </p>
      </div>
    </div>
  </Container>
)
