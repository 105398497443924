import React from 'react'

export default () => (
  <nav className="navbar">
    <div className="container">
      <a className="navbar-brand" href="/">
        <img
          src="/appolus-logo.png"
          alt="Logo Appolus Tecnologia"
          height="40"
        />
      </a>
    </div>
  </nav>
)
