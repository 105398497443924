import React from 'react'

export default ({ name, email }) => (
    <>
      <hr className='my-4' />

      <div id="solicitar-exclusao-dados">
        <h3>Solicitar exclusão da sua conta e seus dados</h3>
        <p>
            Caso você queira remover a sua conta do aplicativo, incluindo todos os
            dados vinculados a ela, basta entrar em contato no e-mail <strong>{email}@appolus.com.br</strong> com
            assunto <strong>"Exclusão de conta e dados"</strong> com o conteúdo conforme exemplo:
            <br />
            <br />
            "Gostaria de solicitar a exclusão total da minha conta e dos dados
            associados à ela no aplicativo {name}.<br />
            Meu CPF é 123.456.789-00."
        </p>
      </div>
    </>
  )
