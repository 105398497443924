import React from 'react'

import { Container } from '../../../components'

export default () => (
  <Container id="privacy-policies">
    <div className="pt-5 my-5">
      <div className="text-center">
        <h3 className="h3">Políticas de privacidade</h3>
        <p className="my-3">
          Aqui você pode consultar as políticas de privacidade de nossos
          aplicativos e sistemas
        </p>
      </div>
      <div className="d-flex justify-content-center">
        <a href="/soucidadao/politicas-de-privacidade" className="btn btn-link">
          souCidadão
        </a>
        <a href="/soudocente/politicas-de-privacidade" className="btn btn-link">
          souDocente
        </a>
        <a href="/sougestor/politicas-de-privacidade" className="btn btn-link">
          souGestor
        </a>
        <a href="/vida-escolar/politicas-de-privacidade" className="btn btn-link">
          Vida Escolar
        </a>
        <a href="/saude/politicas-de-privacidade" className="btn btn-link">
          Saúde
        </a>
        <a href="/educacao/politicas-de-privacidade" className="btn btn-link">
          Educação
        </a>
      </div>
    </div>
  </Container>
)
