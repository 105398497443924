import React from 'react'

import { Container } from '../../../components'

export default () => (
  <Container id="social">
    <div className="pt-5 my-2">
      <div className="text-center">
        <h3 className="h3">Nossas redes</h3>
      </div>
      <div className="mb-3 d-flex justify-content-center">
        <a
          href="https://www.linkedin.com/company/appolustecnologia"
          target="_blank"
          rel="noreferrer"
          className="btn btn-link"
        >
          LinkedIn
        </a>
        <a
          href="https://www.instagram.com/appolus.tec/"
          target="_blank"
          rel="noreferrer"
          className="btn btn-link"
        >
          Instagram
        </a>
        <a
          href="https://www.facebook.com/Appolus-Tecnologia-105255178610521"
          target="_blank"
          rel="noreferrer"
          className="btn btn-link"
        >
          Facebook
        </a>
      </div>
      <div className="text-center">
        <p>E-mail: appolus@appolus.com.br</p>
      </div>
    </div>
  </Container>
)
