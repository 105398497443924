import { useLocation } from 'react-router-dom'
import React, { useState, useMemo } from 'react'
import ReactMarkdown from 'react-markdown'

import { Container, PageHeader } from '../../components'

import filePath from '../../docs/privacy-policies/educacao.md'

function useQuery() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

export default () => {
  const [doc, setDoc] = useState('')

  let query = useQuery()

  let isEmbedded = query.get("embedded") === "true"
  let noBack = query.get("noback") === "true"

  const shouldDisplayBackButton = () => {
    if (isEmbedded) { return false }
    if (noBack) { return false }

    return true
  }

  fetch(filePath)
    .then(res => res.text())
    .then(text => setDoc(text))

  return (
    <>
      {!isEmbedded && <PageHeader />}

      <Container>
        {shouldDisplayBackButton() && <a href="/">{'< Voltar para o início'}</a>}

        <section className="my-4">
          <ReactMarkdown>{doc}</ReactMarkdown>
        </section>
      </Container>
    </>
  )
}
