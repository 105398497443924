export default {
  home: '/',
  privacyPolicies: {
    souCidadao: [
      '/soucidadao/politicas-de-privacidade',
      '/soucidadaopoliticaprivacidade',
    ],
    souDocente: [
      '/soudocente/politicas-de-privacidade',
      '/soudocentepoliticaprivacidade',
    ],
    souGestor: [
      '/sougestor/politicas-de-privacidade',
      '/sougestorpoliticaprivacidade',
    ],
    vidaescolar: [
      '/vida-escolar/politicas-de-privacidade',
      '/vidaescolarpoliticaprivacidade',
    ],
    saude: ['/saude/politicas-de-privacidade', '/lgpdsaudeappolus'],
    educacao: [
      '/educacao/politicas-de-privacidade',
      '/lgpdeducacaoappolus',
      '/educacao/politica-protecao-dados-pessoais',
    ],
  },
}
