import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import {
  Home,
  SaudePrivacyPolicies,
  EducacaoPrivacyPolicies,
  SouCidadaoPrivacyPolicies,
  SouDocentePrivacyPolicies,
  SouGestorPrivacyPolicies,
  VidaEscolarPrivacyPolicies,

} from './screens'

import routes from './utils/routes'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {routes.privacyPolicies.souCidadao.map(path => (
          <Route path={path} element={<SouCidadaoPrivacyPolicies />} exact />
        ))}

        {routes.privacyPolicies.souDocente.map(path => (
          <Route path={path} element={<SouDocentePrivacyPolicies />} exact />
        ))}

        {routes.privacyPolicies.souGestor.map(path => (
          <Route path={path} element={<SouGestorPrivacyPolicies />} exact />
        ))}

        {routes.privacyPolicies.vidaescolar.map(path => (
          <Route path={path} element={<VidaEscolarPrivacyPolicies />} exact />
        ))}

        {routes.privacyPolicies.saude.map(path => (
          <Route path={path} element={<SaudePrivacyPolicies />} exact />
        ))}

        {routes.privacyPolicies.educacao.map(path => (
          <Route path={path} element={<EducacaoPrivacyPolicies />} exact />
        ))}

        <Route path={routes.home} element={<Home />} exact />
      </Routes>
    </BrowserRouter>
  )
}

export default App
