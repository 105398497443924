import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'

import { Container, PageHeader, RequestDeletion } from '../../components'

import filePath from '../../docs/privacy-policies/soudocente.md'

export default () => {
  const [doc, setDoc] = useState('')

  fetch(filePath)
    .then(res => res.text())
    .then(text => setDoc(text))

  return (
    <>
      <PageHeader />
      <Container>
        <a href="/">{'< Voltar para o início'}</a>
        <section className="my-4">
          <ReactMarkdown>{doc}</ReactMarkdown>
          <RequestDeletion name="souDocente" email="soudocente" />
        </section>
      </Container>
    </>
  )
}
